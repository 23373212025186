import React from 'react';
import './projects.css';
import { Article } from '../../components';
import { neuralnet, blackjack, site, space} from './import';
const Projects = () => {
  return (
    <div className='gpt3__blog section__padding' id='project'>
      <div className='gpt3__blog-heading'>
        <h1 className='gradient__text'>Recent Projects</h1>
      </div>
      <div className='gpt3__blog-container'>
        <Article imgUrl={neuralnet} id = 'https://github.com/ty-berg/Simple-Neural-Network' title='Simple Neural Network'/>
        <Article imgUrl={blackjack} id = 'https://github.com/ty-berg/Blackjack' title='Temporal Difference Learning Blackjack'/>
        <Article imgUrl={site} id='https://github.com/ty-berg/Personal-Site' title='Personal Website' />
        <Article imgUrl={space} id='https://github.com/ty-berg/Habitable-Exoplanets-Project' title='Habitable Exoplanets' />
      </div>
    </div>
  )
}

export default Projects
